:root {
  --font-size-big: 2rem;
  --font-size-big-content: 1.1875rem;
  --font-size-medium: 1.5rem;
  --font-size-normal: 1rem;
  --font-size-small: 0.9rem;
  --font-size-tiny: 0.6rem;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  line-height: 1.2;
  /* overflow-y: hidden;
  height: 100vh; */
}

body {
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: unset;
}

@media (max-width: 1366px) {
  html {
    font-size: 13px;
  }
}

@media (max-width: 1024px) {
  html {
    font-size: 10px;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  html {
    font-size: 16px;
  }
}

.nexa-book {
  font-family: poppinsRegular;
}

.nexa-bold {
  font-family: poppinsBold;
}
