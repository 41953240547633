@font-face {
  font-family: "poppinsBold";
  src: url(../public/fonts/Poppins-Bold.ttf);
  line-height: 1;
}

@font-face {
  font-family: "poppinsMedium";
  src: url(../public/fonts/Poppins-Medium.ttf);
  line-height: 1;
}

@font-face {
  font-family: "poppinsRegular";
  src: url(../public/fonts/Poppins-Regular.ttf);
  line-height: 1;
}

@font-face {
  font-family: "coolvetica_rg";
  src: url(../public/fonts/coolvetica_rg_0.otf);
}
/** Generated by FG **/
@font-face {
  font-family: "Conv_MYRIADPRO-BOLD";
  src: url("../public/fonts/MYRIADPRO-BOLD.eot");
  src: local("☺"), url("../public/fonts/MYRIADPRO-BOLD.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
